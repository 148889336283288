<template>
    <section class="section-default section-map">
        <div class="col-left">
            <div class="col-left-container">
                <h2>notre couverture numérique en gms et en galeries</h2>
            </div>
        </div>
        <div class="col-main col-main-first">
            <img src="./../assets/Map.png" alt="map" class="img-map" />
            <div class="map-text-container">
                <div class="map-item">
                    <div class="map-name">Magasins : </div>
                    <div class="map-number mag">251</div>
                </div>
                <div class="map-item">
                    <div class="map-name">Galeries : </div>
                    <div class="map-number">222</div>
                </div>
            </div>
        </div>
    </section>
    <section class="section-default section-geosky">
        <div class="col-left">
            <div class="col-left-container">
                <h2>geosky en action</h2>
            </div>
        </div>
        <div class="col-main">
            <div class="geoksy-item">
                <div class="col-33">
                    <div class="geosky-content">
                        <div class="geosky-name">en gms</div>
                        <div class="geosky-arrow"></div>
                    </div>
                </div>
                <div class="col-66">
                    <img src="./../assets/gms.jpg" alt="geosky" class="geosky-img" />
                </div>
            </div>
            <div class="geosky-sep"></div>
            <div class="geoksy-item">
                <div class="col-33">
                    <div class="geosky-content">
                        <div class="geosky-name">en galeries</div>
                        <div class="geosky-arrow"></div>
                    </div>
                </div>
                <div class="col-66">
                    <img src="./../assets/galeryjpg.jpg" alt="geosky" class="geosky-img" />
                </div>
            </div>
        </div>
    </section>
    <section class="section-brand">
        <h2>quelques marques qui nous font confiance</h2>
    </section>
    <section class="section-brands">
        <brands></brands>
    </section>
</template>

<script>
import Brands from "./../components/Brands.vue";

export default {
    name : "Advert",
    components : {
        Brands
    } 
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .section-map .col-left {
        background:$mainColor;
    }

    .section-map .col-main {
        position: relative;
        background-image:url('./../assets/BG_map.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .img-map {
        max-width: 90%;
    }

    .map-text-container {
        position:absolute;
        bottom:20px;
        left:20px;
    }

    .map-item {
        display: flex;
        align-items: center;
        margin:10px 0;
    }

    .map-name {
        color:#fff;
        font-size:20px;
        width:90px;
    }

    .map-number {
        display: flex;
        justify-content: center;
        align-items: center;
        color:#000;
        font-size:20px;
        height:40px;
        width:40px;
        border-radius: 50%;
        margin-left:10px;
        background:#b4b4b4;
        font-weight:700;
    }

    .map-number.mag {
        background:$mainColor;
        color:#fff;
    }

    .section-geosky .col-left {
        background:$secondColor;
    }

    .section-geosky .col-main {
        display: flex;
        flex-direction: column;
        padding:40px 0;
    }

    .geoksy-item {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .geoksy-item .col-33 {
        width:31%;
        margin:20px 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geoksy-item .col-66 {
        width:64%;
        margin:20px 1%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .geosky-content {
        display:flex;
        align-items: center;
    }

    .geosky-img {
        max-width: 98%;
    }

    .geosky-name {
        color:$secondColor;
        font-size: 25px;
        font-weight:700;
        text-transform: uppercase;
        width:200px;
        height:35px;
        border:2px solid $mainColor;
        border-radius:10px;
        text-align: center;
        margin-right:5px;
    }

    .geosky-arrow {
        height:35px;
        width:35px;
        background-image: url('./../assets/scroll-second.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transform:rotate(-90deg);
    }

    .geosky-sep {
        width:95%;
        height:1px;
        margin:40px auto;
        background:$secondColor;
    }

    .section-brand {
        padding:40px 0;
        display: flex;
        justify-content: center;
        background: $thirdColor;
    }

    .section-brand h2 {
        color:$secondColor;
        font-size:25px;
        text-align: center;
        margin:0;
        padding:0 20px;
        text-transform: uppercase;
    }

    .section-brands {
        margin:80px auto;
        width:1200px;
        max-width: 90%;
        
    }

    @media screen and (max-width:1100px) {
        .section-map .col-left {
            padding-top:160px;
        }

        .geoksy-item {
            flex-direction: column;
            align-items: center;
        }

        .geoksy-item .col-33, .geoksy-item .col-66 {
            width:98%;
            margin:20px auto;
        }

        .geosky-content {
            flex-direction: column;
            align-items: center;
        }

        .geosky-arrow {
            margin-top:5px;
            transform: rotate(0) ;
        }
    }

    @media screen and (max-width:768px) {
        .map-text-container {
            display: flex;
            flex-direction: row;
            right:20px;
            justify-content: space-between;
            bottom:5px;
        }
    }
    
</style>